import Vue from 'vue';
import Vuex from 'vuex';
// import Cookies from 'js-cookie'
import API from '@/api';
import {setLocale} from '../router/index'
Vue.use(Vuex);

// eslint-disable-next-line import/prefer-default-export
export const store = new Vuex.Store({
  state: {
    locale: localStorage.getItem('locale') || 'zh',
    userInfo: [],
    menuActivety: '',
    menuArr: [],
    CouseMenuArr: '',
    condition: {},
    reportForm: {
      tabPaneTab: '定期更换',
      activeName: 'first',
      page: 1,
      count: 10,
    },
    homeForm: {
      activeName: 'first',
      page: 1,
      count: 10,
      scrollHeight: 0,
    },
    mainForm:{
      activeName: 'first',
      page: 0,
      count: 10,
      status: -1,
      type: -1,
      deviceId: ''
    },
    inspectionForm:{
      page: 1,
      count: 10,
      deviceId: '',
      type: -1,
    },
    replacementForm:{
      page: 1,
      count: 10,
      deviceId: '',
    },
    recordsForm:{
      activeName: 'first',
      page: 0,
      count: 10,
      state: 0,
    },
    abnormalForm: {
      activeName: 'first',
      count: 10,
      page:1,
      deviceId: ''
    }
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem('locale', locale);
    },
    setFormDate(state,condition) {
      state.condition = condition
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    SET_MENU: (state, menuActivety) => {
      state.menuActivety = menuActivety;
    },
    SET_MENUARR: (state, menuArr) => {
      state.menuArr = menuArr;
    },
    SET_COURSEMENU: (state, CouseMenuArr) => {
      state.CouseMenuArr = CouseMenuArr;
    },
    reportFormSet:(state, reportForm) => {
      state.reportForm = reportForm;
    },
    homeFormSet:(state, homeForm) => {
      state.homeForm = homeForm;
    },
    mainFormSet:(state, mainForm) => {
      state.mainForm = mainForm;
    },
    inspectionSet:(state, inspectionForm) => {
      state.inspectionForm = inspectionForm;
    },
    replacementSet:(state, replacementForm) => {
      state.replacementForm = replacementForm;
    },
    recordsFormSet:(state, recordsForm) => {
      state.recordsForm = recordsForm;
    },
    abnormalFormSet:(state, abnormalForm) => {
      state.abnormalForm = abnormalForm;
    },
  },
  actions: {
    changeLocale({ commit }, locale) {
      commit('setLocale', locale);
    },
    // 获取个人信息
    getInfo({ commit }) {
      return new Promise((resolve, reject) => {
        // console.log(localStorage.getItem('locale'),'zzzz')
        API.information()
          .then((res) => {
            if (res.message.success) {
              commit('SET_USERINFO', res.message.data);
              if (res.message.data.userlanguage === 'en_US') {
                // this.$i18n.locale = 'zh'
                  localStorage.setItem('locale', 'en')
                setLocale('en')
              } else {
                // this.$i18n.locale = 'en'
                localStorage.setItem('locale', 'zh')
                setLocale('zh')
              }

            }
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 退出登录
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('SET_USERINFO', '');
        resolve();
      });
    },
    reSetMenu({ commit }, key) {
      commit('SET_MENU', key);
    },
    reSetMenuArr({ commit }, menuArr) {
      commit('SET_MENUARR', menuArr);
    },
    reSetCourseMenu({ commit }, CouseMenuArr) {
      commit('SET_COURSEMENU', CouseMenuArr);
    },
  },
  getters: {
    userInfo: (state) => state.userInfo,
    menuActivety: (state) => state.menuActivety,
    menuArr: (state) => state.menuArr,
    CouseMenuArr: (state) => state.CouseMenuArr,
  },
});
