// import request from "../../../../adminplat/src/utils/request";

export default ({ request }) => ({
   // 提报记录列表
  getRecordList(params) {
    return request({
      url: '/newApp/record/list',
      method: 'get',
      params,
    });
  },
  // 待办列表
  getTaskList(params) {
    return request({
      url: '/newApp/task/list',
      method: 'get',
      params,
    });
  },
  // 获取异常提报设备
  abnormaDeviceList() {
    return request({
      url: '/newApp/abnorma/device',
      method: 'get'
    });
  },
  // 故障类型设备
  repairDeviceList() {
      return request({
        url: '/newApp/repair/device',
        method: 'get'
      });
    },
  // 提报原因列表 更换项目列表，
  reasonList(params) {
    return request({
      url: '/newApp/reason/list',
      method: 'get',
      params,
    });
  },
  // 提交异常提报
  SubmissionCreate (data) {
    return request({
      url: '/newApp/abnormal/create',
      method: 'post',
      data
    })
  },
  // 添加定期更换
  submitReplacement (data) {
    return request({
      url: '/newApp/regular/regular',
      method: 'post',
      data
    })
  },
  // 人员选择
  teamList (data) {
    return request({
      url: '/psl/team/user/get/drop',
      method: "post",
      data
    })
  },
  // 验收结果选择设备主管 人员选择
   getManagerUserList () {
    return request({
      url: '/newApp/userList/getManagerUserList',
      method: "get"
    })
  },
  // 提交故障报修
  submitBaoxiu (data) {
    return request({
      url: '/dhk/repair/create/app',
      method: 'post',
      data,
      timeout: 1000 * 60 * 5
    })
  },
  // 定期更换明细
  getExchangeDetail (id) {
    return request({
      url: `/newApp/regular/info/${id}`
    });
  },
  // 异常待机详情
  getExcetionDetail (id) {
    return request({
      url: `/newApp/abnormal/detail/${id}`
    });
  },
  // 审批意见
   abnormalApprove (data) {
    return request({
      url: '/newApp/abnormal/approve',
      method: "post",
      data
    })
  },
  // 设备维修指派信息
 searchList (data) {
    return request({
      url: '/dhk/repair/search',
      method: "post",
      data
    })
  },
  // 设备主管 维修详情
 searchInfo (data) {
    return request({
      url: '/dhk/repair/searchInfo',
      method: "post",
      data
    })
  },
  //// 挂起原因
  pendingList(id) {
      return request({
          url: `/dhk/repair/pendingList/${id}`,
          method: "get",
      })
  },
  // 工单挂起
   repairPending(data) {
    return request({
      url: '/dhk/repair/pending',
      method: 'put',
      data,
    });
  },
  // 解除挂起
 repairCancelPending(id) {
    return request({
      url: `/dhk/repair/cancelPending/${id}`,
      method: 'put',
    });
  },
  // 企业单独 确认完成
  repairComplete(data) {
    return request({
      url: '/dhk/repair/complete',
      method: "post",
      data
    })
  },
  // 维修单的维修信息/dhk/repair/detail/list
   detailInfoList(data) {
    return request({
      url: '/dhk/repair/detail/infoList',
      method: "post",
      data
    })
  },
  // 维修单的维修信息/dhk/repair/detail/list
 detailList(data) {
    return request({
      url: '/dhk/repair/detail/list',
      method: "post",
      data
    })
  },
  // 维修单开始工作/dhk/repair/work/start

 startWork(data) {
    return request({
      url: '/dhk/repair/work/start',
      method: "post",
      data
    })
  },
  // 维修单确认完成/dhk/repair/work/finish
   finishWork(data) {
    return request({
      url: '/dhk/repair/work/finish',
      method: "post",
      data
    })
  },
  // 维修报告提交
  submitReport (data) {
    return request({
      url: '/dhk/repair/detail/create/app',
      method: 'post',
      data,
      timeout: 1000 * 60 * 5
    })
  },
  byRepairId (id) {
    return request({
      url: `/newApp/userList/byRepairId/${id}`,
      method: 'get',
    });
  },
  // 历史维修记录
  getHistoryMantainList(data) {
    return request({
      url: `/dhk/repair/list/hist`,
      method: 'post',
      data
    });
  },
  // 获取点检记录设备
   deviceList (data) {
    return request({
      url: '/newApp/maintain/device',
      method: "get",
      params: data
    })
  },
  // 保养记录
   listmaintainSubmit (data) {
    return request({
      url: '/dkh/maintain/list',
      method: "post",
      data
    })
  },
  getMaintainDetail (deviceMaintainId) {
    return request({
      url: `dkh/maintain/detail`,
      method: 'post',
      data: { deviceMaintainId }
    })
  },
  getMaintainDetaiList (deviceMaintainId) {
    return request({
      url: `dkh/maintain/detail/list`,
      method: 'post',
      data: { deviceMaintainId }
    })
  },
  startMaintain (id) {
    return request({
      url: `newApp/check/start`,
      method: 'post',
      data: { id }
    })
  },
  // 提交保养
  submitMaintain (data) {
    return request({
      data,
      url: `/dkh/maintain/modify`,
      method: 'post',
    })
  },
  //保养切换
   getPointListMaintain (id) {
    return request({
      url: `/dkh/maintain/switch/maintain/${id}`,
      method: "post",
    });
  },
  // 点位详情/newApp/check/pointList
  pointList (data) {
    return request({
      url: '/newApp/check/pointList',
      method: "get",
      params: data
    })
  },
  // 申请取消保养
  checkCancel (data) {
    return request({
      url: '/newApp/check/cancel',
      method: 'post',
      data
    })
  },
  // 获取点检记录
   checkList (data) {
    return request({
      url: '/newApp/check/list',
      method: "get",
      params: data
    })
  },
  // 点检记录详情/newApp/check/detail/{{appCheckDocumentId}}
   checkDetailList (appCheckDocumentId) {
    return request({
      url: `/newApp/check/detail/${appCheckDocumentId}`,
      method: "get",
    })
  },
  // 切换角色
  switchRole(data) {
    return request({
      url: 'psl/user/switchRole',
      method: 'put',
      data,
    });
  },
// 点检单
 InspectionList (appCheckDocumentsId, data) {
  return request({
    url: `/newApp/check/detail/${appCheckDocumentsId}`,
    method: "get",
    params: data
  })
},
  //  保养项目详情
   maintenanceDetails (deviceMaintainId ) {
    return request({
      url: `/dkh/maintain/detail/url/${deviceMaintainId }`,
      method: "get",
    })
  },
  // 点检单提交
 InspectionCheck (data) {
    return request({
      url: '/newApp/check/check',
      method: "post",
      data
    })
  },
  //点检切换
  getPointListSwitch (id) {
    return request({
      url: `/dkh/maintain/switch/${id}`,
      method: "post",
    });
  },
  // 审批取消保养单
   checkApproval (data) {
    return request({
      url: '/newApp/check/approval',
      method: 'post',
      data
    })
  },
  // 定期更换获取信息
  approvalListInfo (appPeriodicReplacementId, data) {
    return request({
      url: `/newApp/regular/info/${appPeriodicReplacementId}`,
      method: "get",
      params: data
    })
  },
  // 定期更换/通过   驳回
   approvalList (data) {
    return request({
      url: '/newApp/regular/approve',
      method: "post",
      data
    })
  },
  // 指派维修工
  dispatchUser (data) {
    return request({
      url: '/dhk/repair/dispatch/user',
      method: "post",
      data
    })
  },
  getErrorDetail (appErrorDeviceId) {
    return request({
      appErrorDeviceId,
      url: `/newApp/abnormal/detail/${appErrorDeviceId}`,
      method: 'get',
    })
  },
  getReportReasonList () {
    return request({ url: `/newApp/reason/list?type=13` });
  },
  submitErrorReport (data) {
    return request({
      data,
      url: `/newApp/abnormal/submit`,
      method: 'post',
    })
  },
  getExcetionSwitch (id) {
    return request({
      url: `/newApp/abnormal/switch/${id}`
    });
  },
// 更换记录设备
regularDeviceList () {
  return request({
    url: '/newApp/regular/device',
    method: "get",
  })
},
  deviceList2 (data) {
    return request({
      url: '/newApp/abnorma/device',
      method: "get",
      params: data
    })
  },
  // 获取更换记录
  regularList (data) {
    return request({
      url: '/newApp/regular/list',
      method: "get",
      params: data
    })
  },
// 维修记录/dhk/repair/list/my
 listmaintainRepair (data) {
  return request({
    url: '/dhk/repair/list/my',
    method: "post",
    data
  })
},
  regularList2(data) {
    return request({
      url: '/newApp/abnormal/list',
      method: "get",
      params: data
    })
  },

});
