<template>
  <div id="sidebar-right" class="right-sidebar">
    <!--默认图标-->
    <img class="default" alt="" src="@/assets/img/Slice8.png" />
    <!--悬浮显示-->
    <div class="qrcode">
      <span class="text">{{ $t('Follow_us') }}</span>
      <img alt="img" src="@/assets/img/qrcode-wx.jpg" />
    </div>

    <!--    <ol>-->
    <!--      <li>-->
    <!--        <a href="#">-->
    <!--          <i class="el-icon-headset"></i>-->
    <!--          在线客服-->
    <!--        </a>-->
    <!--      </li>-->
    <!--      <li>-->
    <!--        <a href="#">-->
    <!--          <i class="el-icon-s-order"></i>-->
    <!--          留言咨询-->
    <!--        </a>-->
    <!--      </li>-->
    <!--      <li>-->
    <!--        <a href="#">-->
    <!--          <i class="el-icon-menu"></i>-->
    <!--          关注我们-->
    <!--        </a>-->
    <!--      </li>-->
    <!--    </ol>-->
  </div>
</template>

<script>
// import API from '@/api'
// import cookies from '@/utils/cookies'
export default {
  // name: 'sidebar-right'
};
</script>

<style lang="scss" scoped>
/*右侧悬浮*/
.right-sidebar {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 999;
  width: 40px;
  height: 80px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background: #F3A100;
  cursor: pointer;
  box-shadow: -2px 0 4px 1px rgba(147, 147, 147, 0.25);
  &:hover {
    .qrcode {
      display: block;
    }
  }
  /*默认图标*/
  .default {
    display: inline-block;
    margin: 30px 10px;
  }
  /*二维码*/
  .qrcode {
    display: none;
    background: #fff;
    width: 116px;
    height: 144px;
    font-size: 20px;
    text-align: center;
    border: 4px solid #F3A000;
    border-right: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    position: absolute;
    top: -50%;
    right: 0;
    padding: 20px 16px 16px;
    box-shadow: -2px 0 4px 1px rgba(146, 146, 146, 0.25);
    // 文本
    .text {
      display: block;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 6px;
      white-space: nowrap;
      line-height: 1;
    }
    img {
      display: inline-block;
      width: 80px;
      height: 80px;
      margin: 0 auto;
    }
  }
}

/*#sidebar-right {*/
/*  position: fixed;*/
/*  top: 160px;*/
/*  right: 35px;*/
/*}*/
/*ol li {*/
/*  width: 10px;*/
/*  margin-bottom: 20px;*/
/*}*/
/*ol li a {*/
/*  text-decoration: none;*/
/*  color: rgb(247, 200, 86);*/
/*  border: 1px solid rgb(215, 215, 215);*/
/*  background-color: rgb(249, 249, 249);*/
/*  display: inline-block;*/
/*  padding: 8px;*/
/*}*/

@media screen and (max-width:800px) {
  .right-sidebar {
    display: none;
  }
}
</style>
