import menuTitles from './router';
import {store} from "@/store";
// 更新路由的 meta 信息
export function updateRouteMeta(router, locale) {
  // console.log(router,'4444444')
  // console.log('------------')
  router.forEach(route => {
    // console.log(route)
    if (route.meta) {
      // console.log(route.path)
      console.log(menuTitles[locale])
      // console.log(menuTitles[locale][route.path.split('/').pop()])
      route.meta.title = getMenuTitle(  route.path, locale);
      // console.log(route.meta.title)
    }
    if (route.children) {
      route.children.forEach(childRoute => {
        if (childRoute.meta) {
          childRoute.meta.title = getMenuTitle( childRoute.path, locale);
        }
      });
    }
  });
  // console.log(router)
}
export function getMenuTitle(path, locale) {
  // console.log(locale)
  // console.log(localStorage.getItem('locale'))
  // console.log(path)
  // console.log(menuTitles[locale][path.split('/').pop()])
  return menuTitles[locale] && menuTitles[locale][path.split('/').pop()] || '';
}

