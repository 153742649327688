import axios from 'axios';
import { get } from 'lodash';
import { MessageBox, Message } from 'element-ui';
import cookies from '@/utils/cookies';
import { errorLog, errorCreate } from './tools';

/**
 * @description 创建请求实例
 */
function createService() {
  // 创建一个 axios 实例
  const service = axios.create();
  // 请求拦截
  service.interceptors.request.use(
    (config) => config,
    // eslint-disable-next-line comma-dangle
    (error) => Promise.reject(error) // send failed
  );
  // 响应拦截
  service.interceptors.response.use(
    // eslint-disable-next-line consistent-return
    (response) => {
      // dataAxios 是 axios 返回数据中的 data
      const dataAxios = response.data;
      if (dataAxios.code !== '200') {
        if (dataAxios.code === '510') {
          MessageBox.confirm(localStorage.getItem('locale') !== 'en' ? "账号不存在或登录凭证失效，请重新登录！" : 'The account does not exist or the login credentials are invalid, please log in again!', '', {
            confirmButtonText: localStorage.getItem('locale') !== 'en' ? '重新登录' : 'Log In Again',
            cancelButtonText: localStorage.getItem('locale') !== 'en' ? '取消' : 'Cancel',
            type: '警告',
          })
            .then(() => {
              if(localStorage.getItem('YGS')) {
                window.open(`${process.env.VUE_APP_LOGIN}login/:SUP/?YGS=PringManager`, '_self');
              } else {
                window.open(`${process.env.VUE_APP_LOGIN}login/:SUP/`, '_self');
              }
            })
            .catch(() => {
              if(localStorage.getItem('YGS')) {
                window.open(`${process.env.VUE_APP_LOGIN}login/:SUP/?YGS=PringManager`, '_self');
              } else {
                window.open(`${process.env.VUE_APP_LOGIN}login/:SUP/`, '_self');
              }
            });
        } else {
          Message({
            message: dataAxios.message.message || 'Error',
            type: 'error',
            // showClose: true,
            duration: 5 * 1000,
          });
        }
      }
      // 这个状态码是和后端约定的
      const { code } = dataAxios.code;
      // 根据 code 进行判断
      if (code === undefined) {
        // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
        return dataAxios;
      }
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (code) {
        case '200':
          // [ 示例 ] code === 0 代表没有错误
          return dataAxios.newslist;
        case 'xxx':
          // [ 示例 ] 其它和后台约定的 code
          errorCreate(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`);
          break;
        default:
          // 不是正确的 code
          errorCreate(`${dataAxios.msg}: ${response.config.url}`);
          break;
      }
    },
    (error) => {
      const status = get(error, 'response.status');
      switch (status) {
        case '400':
          error.message = '请求错误';
          break;
        case '401':
          error.message = '未授权，请登录';
          break;
        case '403':
          error.message = '拒绝访问';
          break;
        case '404':
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case '408':
          error.message = '请求超时';
          break;
        case '500':
          error.message = '服务器内部错误';
          break;
        case '501':
          error.message = '服务未实现';
          break;
        case '502':
          error.message = '网关错误';
          break;
        case '503':
          error.message = '服务不可用';
          break;
        case '504':
          error.message = '网关超时';
          break;
        case '505':
          error.message = 'HTTP版本不受支持';
          break;
        default:
          break;
      }
      errorLog(error);
      return Promise.reject(error);
    // eslint-disable-next-line comma-dangle
    }
  );
  return service;
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequestFunction(service) {
  return function (config) {
    const token = cookies.get('token');
    const configDefault = {
      headers: {
        token: token || '',
        'Content-Type': get(config, 'headers.Content-Type', 'application/json'),
      },
      timeout: 5000,
      baseURL: process.env.VUE_APP_API,
      data: {},
    };
    return service(Object.assign(configDefault, config));
  };
}

// 用于真实网络请求的实例和请求方法
export const service = createService();
export const request = createRequestFunction(service);
