// menuTitles.js
const menuTitles = {
  'zh': {
    team: '团队管理',
    personnel_management: '人员管理',
    team_information: '团队信息',
    shift_management: '班组管理',
    meal_management: '用餐管理',
    external_maintenance: '外部维修工管理',
    equipment_maintenance: '设备运维',
    submission_record:'提报记录',
    big_screen_display_boss:'老板端',
    big_screen_display_workshop:'车间端',
    big_screen_display_machine:'机台端',
    big_screen_display: '可视化管理',
    submission_reason:'提报原因',
    device_list: '设备列表',
    template_list:'模板列表',
    technical_support: '技术支持',
    Data_Report: '数据报告',
    maintenance_record:'保养记录',
    inspection_record:'点检记录',
    replacement_record:'更换记录',
    repair_record:'维修记录',
    Plate_Quality: '制版质量',
    zhiliangReport: '质量报告',
    prepress_automation:'印前自动化',
    approval_record: '审批记录',
    Plate_maintenance:'版材维护',
    day_Week_Clearing: '日清周清',
  },
  'en': {
    team: 'Team Management',
    personnel_management: 'Member',
    team_information: 'Team Info',
    shift_management: 'Shift',
    meal_management: 'Meal',
    external_maintenance: 'External Worker',
    equipment_maintenance: 'Device Management',
    submission_record:'Submission Record',
    big_screen_display: 'Visual Management',
    big_screen_display_boss:'Boss Visual',
    big_screen_display_workshop:'Workshop Visual',
    big_screen_display_machine:'Device Visual',
    submission_reason:'Reason',
    device_list: 'Device List',
    template_list:'Template List',
    technical_support: 'Technical Support',
    Data_Report: 'Data Report',
    maintenance_record:'Maintenance Record',
    inspection_record:'Inspection Record',
    replacement_record:'Replacement Record',
    repair_record:'Repair Record',
    Plate_Quality: 'Plate Quality',
    zhiliangReport: 'Quality Report',
    prepress_automation:'Prepress automation',
    approval_record: 'approval record',
    Plate_maintenance:'Plate Maintenance',
    day_Week_Clearing:'Day & Week Work'
    // 其他英文标题...
  }
};

export default menuTitles;
