export default ({ request }) => ({
  /**
   * @description 测试接口
   * @param {Object} data 携带的信息
   */
  // USER_INFO(data = {}) {
  //   // 接口请求
  //   return request({
  //     url: '/woman/index',
  //     method: 'get',
  //     params: data
  //   })
  // }
  /**
   * 课程精选 获取课程封面和标题
   * @param { Object } token
   */
  course() {
    return request({
      url: '/customer/course/list',
      method: 'get',
    });
  },
  getTeamUser(data) {
    return request({
      url: '/psl/team/user/get',
      method: 'post',
      data,
    });
  },
  // 添加
  invite(data) {
    return request({
      url: '/psl/team/apply/invite',
      method: 'post',
      data,
    });
  },
  // 删除
  deleteUser(data) {
    return request({
      url: '/psl/team/user/delete',
      method: 'post',
      data,
    });
  },
  // 编辑
  saveRole(data) {
    return request({
      url: '/psl/team/user/role/save',
      method: 'post',
      data,
    });
  },
  CompanyRole() {
    return request({
      url: '/psl/role/team/roles',
      method: 'post',
    });
  },

  /**
   * 我的课程 获取学习中的课程
   * @param { Object } token
   */
  studyCourse() {
    return request({
      url: '/customer/course/learningList',
      method: 'get',
    });
  },
  /**
   * 我的课程 获取已学完课程
   * @param { Object } token
   */
  finishCourse() {
    return request({
      url: '/customer/course/finishList',
      method: 'get',
    });
  },
  // 我的课程 取消报名
  cancelCourse(data) {
    return request({
      url: '/customer/course/cancelCourse',
      method: 'put',
      data,
    });
  },
  /**
   * 获取作业章节
   * @param { Object } data
   */
  Task(data) {
    return request({
      url: '/customer/course/homeWorkList',
      method: 'get',
      params: data,
    });
  },
  /**
   * 获取作业详情信息
   * @param { Object } data
   */
  TaskParticular(data) {
    return request({
      url: '/customer/course/homeWorkInfo',
      method: 'get',
      params: data,
    });
  },
  /**
   * 获取课堂播放详情
   * @param { Object }data
   */
  CourseUserInfo(data) {
    return request({
      url: '/customer/course/getCourseUserInfo',
      method: 'get',
      params: data,
    });
  },
  /**
   * 获取考试内容
   * @param { Object }data
   */
  Test(data) {
    return request({
      url: '/customer/course/testInfo',
      method: 'get',
      params: data,
    });
  },
  /**
   * 提交答案
   * @param { Object }data
   */
  getAnswer(data) {
    return request({
      url: '/customer/course/submitTestInfo',
      method: 'post',
      data,
    });
  },
  /**
   * 成绩查看
   * @param { Object } data
   */
  grade(data) {
    return request({
      url: '/customer/course/testFinishInfo',
      method: 'get',
      params: data,
    });
  },
  /**
   * 获取个人信息
   * @param { header } token
   */
  information() {
    return request({
      url: '/psl/user/info/get',
      method: 'post',
    });
  },
  /**
   * 个人中心--密码设置
   * @param { Object }data
   */
  repassword(data) {
    return request({
      url: '/psl/user/password/modify',
      method: 'post',
      data,
    });
  },
  /**
   * 个人资料--修改名称
   * @param { Object }data
   */
  name(data) {
    return request({
      url: '/psl/user/info/modifyNew',
      method: 'post',
      data,
    });
  },
  /**
   * 个人资料 --发送旧手机验证码
   * @param { token } header
   */
  phone() {
    return request({
      url: '/psl/user/code/old/phone',
      method: 'post',
    });
  },
  /**
   * 个人资料 --判断旧验证码手机号
   * @param { Object } data
   */
  cheackPhone(data) {
    return request({
      url: '/psl/user/code/old/phone/check',
      method: 'post',
      data,
    });
  },
  /**
   * 个人资料 --新手机号发送验证码
   * @param { Object } data
   */
  getNewPhone(data) {
    return request({
      url: '/psl/user/code/modify/phone',
      method: 'post',
      data,
    });
  },
  /**
   * 个人资料 -- 验证新手机号验证码
   * @param { Object } data
   */
  cheackNewPhone3(data) {
    return request({
      url: '/psl/user/phone/modify',
      method: 'post',
      data,
    });
  },
  /**
   * 个人中心 -- 我的订单 -- 获取全部订单
   * @param { Object } data
   */
  Indent(data) {
    return request({
      url: '/customer/course/getOrderList',
      method: 'get',
      params: data,
    });
  },
  // 团队管理，设备运维埋点数据接口
  userLog(data) {
    return request({
      url: '/psl/user/userLog',
      method: 'post',
      data,
    });
  },
  /**
   * 个人中心 -- 我的订单 -- 消息中心
   * @param { Object } data
   */
  message(data) {
    return request({
      url: '/message/center/list',
      method: 'post',
      data,
    });
  },
  /**
   * 个人中心 -- 我的订单 -- 删除功能
   * @param { Object } data
   */
  IndentDelete(data) {
    return request({
      url: '/customer/course/deleteOrder',
      method: 'delete',
      data,
    });
  },
  /**
   * 个人中心 -- 我的订单 -- 取消功能
   * @param { Object }data
   */
  cancel(data) {
    return request({
      url: '/customer/course/cancelOrder',
      method: 'put',
      data,
    });
  },
  /**
   * 个人中心 -- 我的订单 --查看发票
   * @param { Object }data
   */
  checkInvoice(data) {
    return request({
      url: '/order/invoice/detail',
      method: 'get',
      params: data,
    });
  },
  /**
   * 个人中心 -- 我的订单 -- 开发票
   * @param { Object } data
   */
  submitInvoice(data) {
    return request({
      url: '/customer/course/submitInvoice',
      method: 'post',
      data,
    });
  },
  /**
   * 取消订单
   * @param { Object } id
   */
  cancelOrder(data) {
    return request({
      url: '/app/courseOrder/cancelOrder',
      method: 'get',
      data,
    });
  },
  /**
   * 上传头像
   *@param { FormData } data
   */
  loadHeadImg(data) {
    return request({
      url: '/psl/user/url/modify',
      method: 'post',
      data,
    });
  },
  /**
   * 获取应刷管家Token
   * @param { null }
   */
  getPrinting() {
    return request({
      url: '/psl/user/token/get',
      method: 'get',
    });
  },
  /**
   * 获取播放内容
   * @param { object } data
   */
  getPlay(data) {
    return request({
      url: '/customer/course/getSectionUserInfo',
      method: 'get',
      params: data,
    });
  },
  /**
   * 视频播放结束
   * @param { Object } data
   */
  EndPlay(data) {
    return request({
      url: '/app/userCourse/playOver',
      method: 'post',
      data,
    });
  },
  // 获取所有页面即按钮
  getMenu() {
    return request({
      url: '/psl/menu/get',
      method: 'post',
    });
  },

  // 创建角色
  createRole(data) {
    return request({
      url: '/psl/role/create',
      method: 'post',
      data,
    });
  },

  // 查询团队角色
  getTeamRoles() {
    return request({
      url: '/psl/role/team/roles',
      method: 'post',
    });
  },

  // 修改角色名
  updateRole(data) {
    return request({
      url: '/psl/role/update',
      method: 'post',
      data,
    });
  },

  // 删除角色
  deleteRole(data) {
    return request({
      url: '/psl/role/delete',
      method: 'post',
      data,
    });
  },

  // 保存角色目录权限
  setRolepermission(data) {
    return request({
      url: '/psl/role/permission/save',
      method: 'post',
      data,
    });
  },

  handelMenus(data) {
    return request({
      url: '/psl/role/permission/menus/handel',
      method: 'post',
      data,
    });
  },

  // 获取角色的目录权限
  getSelfMenu() {
    return request({
      url: '/psl/role/permission/menus',
      method: 'post',
    });
  },
  getProvince() {
    return request({
      url: '/psl/provinces/get',
      method: 'post',
    });
  },
  // 获取模块信息
  getAllModules() {
    return request({
      url: '/psl/module/list',
      method: 'post',
    });
  },

  // 修改角色权限配置
  modifyAuthConfig(data) {
    return request({
      url: '/psl/team/role/config/modify',
      method: 'post',
      data,
    });
  },
  /**
   * 考试按钮
   * @param { Object } data
   */
  getTest(data) {
    return request({
      url: 'customer/course/isTest',
      method: 'get',
      params: data,
    });
  },
// 空间使用
  prepressProject() {
    return request({
      url: '/prepressProject/info',
      method: 'get',
    });
  },
  //消息
  infoMessage() {
    return request({
      url: '/psl/user/info/message',
      method: "post",
    })
  }
});

